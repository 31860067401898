var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headers'),_c('div',{staticClass:"mb-30"},[_c('div',{staticClass:"relative",staticStyle:{"opacity":"1"}},[_c('div',{staticClass:"top-0 left-0 z-[-1] h-full w-full relative md:absolute",attrs:{"data-testid":"hero-image-bg"}},[_c('img',{staticClass:"h-full w-full object-cover object-center object-cover object-bottom",staticStyle:{"object-position":"50% 50%"},attrs:{"src":require('@/assets/images/InvestmentPortfolio/b1.png'),"alt":"","draggable":"false"}})]),_vm._m(0)])]),_c('div',{staticStyle:{"overflow-x":"hidden"}},[_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"main-grid mb-30"},[_c('div',{staticClass:"main-grid--full-grid"},[_c('div',{staticClass:"flex flex-col relative"},[_c('div',{staticClass:"block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"},[_c('div',{staticClass:"pb-[30px] md:pb-0 col-span-11 col-start-2 relative"},[_c('div',[_c('section',{},[_c('div',{staticClass:"relative overflow-hidden rounded h-[216px] lg:h-[291px]",staticStyle:{"opacity":"1","transform":"none"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require('@/assets/images/InvestmentPortfolio/b3.png')}})]),_vm._m(4)])])]),_c('div',{staticClass:"col-span-11 col-start-14 relative"},[_c('div',[_c('section',{},[_c('div',{staticClass:"relative overflow-hidden rounded h-[216px] lg:h-[291px]",staticStyle:{"opacity":"1","transform":"none"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require('@/assets/images/InvestmentPortfolio/b4.png')}})]),_vm._m(5)])])]),_c('div',{staticClass:"absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"})])])])])]),_c('footers')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"},[_c('div',{staticClass:"col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"},[_c('div',{staticClass:"relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start"},[_c('h1',{staticClass:"text-2xl md:text-4xl text-iblack-300 dark:text-iblack-300 max-w-[540px]"},[_vm._v(" With Differentiated Capital, We Seek Differentiated Outcomes. ")]),_c('p',{staticClass:"text-lg text-iblack-300 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]"},[_vm._v(" Our goal is to build resilient portfolios that compound returns"),_c('br',{staticClass:"hidden md:inline"}),_vm._v(" from diverse sources, across public and private markets. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-grid mb-30"},[_c('div',{staticClass:"flex items-center main-grid--in-grid justify-center"},[_c('div',{staticClass:"block md:grid md:grid-cols-6 md:gap-4 w-full"},[_c('div',{staticClass:"col-span-6"},[_c('div',{staticClass:"prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-center",staticStyle:{"opacity":"1","transform":"none"}},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"my-[30px] font-medium"},[_vm._v(" Our Investment Strategies Draw on Opportunities"),_c('br',{staticClass:"hidden md:inline"}),_vm._v(" Across Public and Private Markets. ")]),_c('p',{staticClass:"m-0"})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-grid mb-30"},[_c('div',{staticClass:"relative transition-opacity duration-300 overflow-hidden main-grid--in-grid",staticStyle:{"opacity":"1","transform":"none"}},[_c('div',{staticClass:"col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden",attrs:{"data-testid":"tout-video-bg"}},[_c('div',{staticClass:"relative w-full h-full overflow-hidden max-h-[80vh] min-h-[100%] w-full"},[_c('div',{staticStyle:{"width":"100%","height":"100%"},attrs:{"autoplay":""}},[_c('video',{staticClass:"object-cover object-center h-[550px] md:min-h-[101%]",staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://stream.mux.com/PJUT4Gad6mylTGK02idYFJJCnEIntLCx9/high.mp4","preload":"auto","autoplay":"","loop":"","crossorigin":"anonymous","playsinline":"","webkit-playsinline":"","x5-playsinline":""}})])])]),_c('div',{staticClass:"md:h-[550px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[550px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"},[_c('div',{staticClass:"col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"},[_c('div',{staticClass:"mt-[30px] md:my-0",staticStyle:{"opacity":"1","transform":"none"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-grid mb-30"},[_c('div',{staticClass:"inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block"},[_c('div',{staticClass:"w-full h-full block",staticStyle:{"background-color":"rgb(155, 155, 155)","opacity":"1","transform":"none"},attrs:{"role":"separator","aria-orientation":"horizontal"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block md:grid md:grid-cols-6 md:gap-4"},[_c('div',{staticClass:"col-span-5"},[_c('h1',{staticClass:"text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl",staticStyle:{"opacity":"1","transform":"none"}},[_vm._v(" Total Portfolio Management through Outsourced CIO Capabilities ")]),_c('p',{staticClass:"text-iblack-600 mt-2.5",staticStyle:{"opacity":"1","transform":"none"}},[_vm._v(" With total portfolio management, ZEN CAPITAL manages a range of strategies to seek optimal outcomes for an investor’s unique risk and liquidity profile. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block md:grid md:grid-cols-6 md:gap-4"},[_c('div',{staticClass:"col-span-5"},[_c('h1',{staticClass:"text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl",staticStyle:{"opacity":"1","transform":"none"}},[_vm._v(" Multi-Strategy Partnerships:"),_c('br',{staticClass:"hidden md:inline"}),_vm._v(" Portfolio Management through Select Strategies ")]),_c('p',{staticClass:"text-iblack-600 mt-2.5",staticStyle:{"opacity":"1","transform":"none"}},[_vm._v(" With select strategies, ZEN CAPITAL manages a portion of the investor’s portfolio. ")])])])
}]

export { render, staticRenderFns }