<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" style="opacity: 1">
        <div data-testid="hero-image-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <img
              class="h-full w-full object-cover object-center object-cover object-bottom"
              :src="require('@/assets/images/InvestmentPortfolio/b1.png')"
              alt=""
              draggable="false"
              style="object-position: 50% 50%"
          />
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-300 dark:text-iblack-300 max-w-[540px]">
                With Differentiated Capital, We Seek Differentiated Outcomes.
              </h1>
              <p class="text-lg text-iblack-300 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">                
                Our goal is to build resilient portfolios that compound returns<br class="hidden md:inline" />
                from diverse sources, across public and private markets.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <div class="flex items-center main-grid--in-grid justify-center">
          <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
            <div class="col-span-6">
              <div
                class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-center"
                style="opacity: 1; transform: none"
              >
                <div class="text-center">
                  <h1 class="my-[30px] font-medium">
                    Our Investment Strategies Draw on Opportunities<br class="hidden md:inline" />
                    Across Public and Private Markets.
                  </h1>
                  <p class="m-0"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div
          class="relative transition-opacity duration-300 overflow-hidden main-grid--in-grid"
          style="opacity: 1; transform: none"
        >
          <div
            data-testid="tout-video-bg"
            class="col-start-2 col-span-23 rounded md:h-full w-full sm:relative md:absolute top-0 left-0 z-[-1] overflow-hidden"
          >
            <div class="relative w-full h-full overflow-hidden max-h-[80vh] min-h-[100%] w-full">
              <div autoplay="" style="width: 100%; height: 100%">
                <video
                  src="https://stream.mux.com/PJUT4Gad6mylTGK02idYFJJCnEIntLCx9/high.mp4"
                  preload="auto"
                  autoplay=""
                  loop=""
                  crossorigin="anonymous"
                  class="object-cover object-center h-[550px] md:min-h-[101%]"
                  playsinline=""
                  webkit-playsinline=""
                  x5-playsinline=""
                  style="width: 100%; height: 100%"
                ></video>
                <!-- <img style="width: 100%; height: 100%" :src="require('@/assets/images/InvestmentPortfolio/b2.png')"> -->
              </div>
            </div>
          </div>
          <div
            class="md:h-[550px] grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:h-[550px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] col-start-2 col-span-full relative"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-1 col-span-15 md:col-start-3 lg:col-start-3 justify-center"
            >
              <div class="mt-[30px] md:my-0" style="opacity: 1; transform: none"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <section class="">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style="opacity: 1; transform: none"
                    >
                      <!-- <picture class="block transition-opacity duration-500 h-full w-full opacity-100"
                        ><source
                          srcset="
                            https://www.datocms-assets.com/65181/1655934686-pie-chart_1.gif?dpr=0.25  320w,
                            https://www.datocms-assets.com/65181/1655934686-pie-chart_1.gif?dpr=0.5   640w,
                            https://www.datocms-assets.com/65181/1655934686-pie-chart_1.gif?dpr=0.75  960w,
                            https://www.datocms-assets.com/65181/1655934686-pie-chart_1.gif          1280w
                          "
                          sizes="(min-width: 768px) 50vw, 100vw" />
                        <img
                          class="h-full w-full object-cover object-center"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                          alt=""
                          draggable="false"
                      /></picture> -->
                      <img style="width: 100%; height: 100%" :src="require('@/assets/images/InvestmentPortfolio/b3.png')">
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style="opacity: 1; transform: none"
                        >
                          Total Portfolio Management through Outsourced CIO Capabilities
                        </h1>
                        <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                          With total portfolio management, ZEN CAPITAL manages a range of strategies to seek optimal outcomes
                          for an investor’s unique risk and liquidity profile.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <section class="">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style="opacity: 1; transform: none"
                    >
                      <!-- <picture class="block transition-opacity duration-500 h-full w-full opacity-100"
                        ><source
                          srcset="
                            https://www.datocms-assets.com/65181/1655938108-piechart4.gif?dpr=0.25  320w,
                            https://www.datocms-assets.com/65181/1655938108-piechart4.gif?dpr=0.5   640w,
                            https://www.datocms-assets.com/65181/1655938108-piechart4.gif?dpr=0.75  960w,
                            https://www.datocms-assets.com/65181/1655938108-piechart4.gif          1280w
                          "
                          sizes="(min-width: 768px) 50vw, 100vw" />
                        <img
                          class="h-full w-full object-cover object-center"
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                          alt=""
                          draggable="false"
                      /></picture> -->
                      <img style="width: 100%; height: 100%" :src="require('@/assets/images/InvestmentPortfolio/b4.png')">
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style="opacity: 1; transform: none"
                        >
                          Multi-Strategy Partnerships:<br class="hidden md:inline" />
                          Portfolio Management through Select Strategies
                        </h1>
                        <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                          With select strategies, ZEN CAPITAL manages a portion of the investor’s portfolio.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
